@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Orbit&family=Pixelify+Sans:wght@400..700&display=swap');


/* Base styles */
body {
  font-family: "Pixelify Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  @apply m-0 p-0 bg-gradient-to-b from-secondary to-accent;
  @apply bg-[url('../assets/bgMBZ.png')] bg-cover;
}

::selection {
  color: #0A0C13;
  background: #10f678;
}

::-moz-selection {
  color: #0A0C13;
  background: #10f678;
}

.prose-custom {
  @apply prose prose-sm text-neutral max-w-none font-sans;
}

/* Main content */
.main-content {
  @apply min-h-screen flex flex-col;
}

/* Header */
.header {
  @apply px-4 py-2 text-center text-base-100 self-center mt-8;
  /* @apply sticky top-0 z-[2000] backdrop-blur-lg; */
}

.header h1 {
  @apply text-2xl mt-2 font-mono;
}

.header a {
  text-align: center;
  text-align: -webkit-center;
}

.header img {
  @apply max-h-10;
}

nav {
  @apply flex justify-center gap-4 mt-4 text-sm items-center text-greenaccent;
}

.nav-item {
  @apply cursor-pointer hover:text-accent;
}

.dropdown-content {
  @apply absolute text-base-100 p-2 rounded-lg shadow-lg bg-greenaccent rounded-box w-52 z-50 text-center font-sans items-center;
}

/* Toggle buttons */
.toggle-buttons {
  @apply flex justify-center gap-2 p-2;
}

.toggle-buttons > button {
  @apply p-3 rounded-md cursor-pointer backdrop-brightness-125 backdrop-blur-lg text-sm lg:text-2xl;
  @apply hover:transition-all	duration-300 ease-in-out hover:backdrop-brightness-50;
}

.text-button {
}

/* Layout */
.bento-container {
  @apply p-8 w-full md:grid grid-flow-row gap-8 grid-cols-3;
}

.with-scrollbar {
  /* https://adoxography.github.io/tailwind-scrollbar/examples */
  @apply overflow-x-scroll px-4 scrollbar;
}

.break-title {
  @apply text-center justify-center m-0 p-4 text-4xl bg-greenaccent text-base-100;
}

/* General card */
.bento-card {
  @apply row-span-1 overflow-clip shadow-xl text-accent backdrop-blur-md backdrop-brightness-[40%];
  @apply mb-8 md:mb-0;
  @apply border-2 border-greenaccent;
}

.timeline-box {
  @apply bg-greenaccent text-base-100;
}

.timeline > li > .timeline-box, 
.exhibition-arts-container > .exhibition-art,
.bento-card th,
{
  @apply text-accent bg-greenaccent;
}

.bento-card th {
  @apply rounded-t-lg border-none;
}

.card-title {
  @apply p-2 text-sm font-bold text-center justify-center uppercase bg-greenaccent text-base-100;
}

.bento-card th, hr {
  @apply bg-accent text-base-100;
}

.bento-card .with-scrollbar {
  @apply scrollbar-track-base-100 scrollbar-thumb-neutral;
}

.card-footer {
  @apply px-4 pb-4;
}

/* Specific cards */

/* Day */

.food-type {
  @apply font-sans ml-8
}

.food-type h3 {
  @apply font-bold text-lg text-center justify-center
}

.food-type h4 {
  @apply font-bold mx-12 text-lg text-center justify-center
}

.food-type p {
  @apply m-6
}

/* Event info */

.event-info > .with-padding {
  @apply p-4;
}

.event-date,
.event-description {
  @apply mb-4;
}

.artist-minor {
  @apply text-sm font-normal;
}

.artist-name {
  @apply font-bold cursor-pointer underline decoration-2;
}

.artist-name:hover {
  @apply animate-pulse decoration-wavy;
}

.artist-name-no-link {
  @apply font-bold;
}

.minor-text {
  @apply text-xs font-normal opacity-50;
}

.minor-text:hover {
  @apply opacity-100;
}

.social-media-links {
  @apply flex flex-wrap gap-4 justify-around py-2 rounded-md text-accent;
}

.social-media-links > span {
  @apply bg-base-100 p-1 rounded-md;
}

.social-media-links > span > a {
  @apply cursor-pointer hover:text-secondary underline;
}

.timeline {
  @apply py-4;
}

.x-button {
  @apply btn absolute right-1 top-1 text-greenaccent border-greenaccent rounded-none;
}

.collapse {
  @apply px-8;
}

.collapse-title {
  @apply text-center p-1 mb-2 content-center bg-base-100 border-2 border-greenaccent;
}

/* Exhibition */

.exhibition-arts-container {
  @apply grid grid-cols-2 gap-4 p-4 lg:grid-cols-4;
}

.exhibition-art {
  @apply p-2 rounded-md flex;
}

.exhibition-art span {
  @apply text-xs;
}

/* Calendar */

.calendar-table-container {
  @apply flex flex-row flex-nowrap items-start overflow-y-hidden;
}

.my-calendar table {
  @apply m-1 w-full border-collapse table;
}

.my-calendar th,
.my-calendar td {
  @apply text-center p-3;
}

.my-calendar tr {
  @apply border-b border-neutral border-opacity-25;
}

.my-calendar tr:hover {
  @apply bg-greenaccent;
}

.my-calendar tr:last-child {
  @apply border-none;
}

.my-calendar tbody {
  @apply rounded-b-lg backdrop-brightness-125;
}

.clickable {
  @apply p-4;
}

.event-list {
  @apply list-none p-0 m-0;
}

.event-list li {
  @apply mb-1;
}

.calendar-layout {
  @apply m-6 mb-24 text-accent text-lg backdrop-blur-lg backdrop-brightness-50 rounded-lg;
}

.calendar-layout .card-title,
.calendar-layout .card-footer {
  @apply hidden;
}

.calendar-layout .calendar-table-container {
  @apply flex flex-col flex-nowrap items-start w-auto;
  /* @apply lg:grid grid-cols-2 gap-4; */
}

.calendar-layout td {
  @apply border-none
}

.calendar-layout th {
  @apply mb-4 p-2 text-center justify-center bg-accent rounded-lg text-base-100 border-none;
}

.time-place-entry {
  @apply text-sm truncate flex items-center justify-start font-sans;
}

/* Modal */

.modal {
  width: 100vw;
  height: 100vh;
  @apply backdrop-blur-lg backdrop-brightness-50;
}

.modal-box {
  @apply border-2 border-greenaccent rounded-none;
  @apply scrollbar overflow-y-scroll;
}

.modal-title {
  @apply font-bold text-xl bg-greenaccent text-base-100 my-2;
}

.modal, .modal-box {
  @apply min-w-24 min-h-24 p-4 md:p-8;
}

/* Map */

.map-container {
  @apply w-full h-full;
}

.map-marker {
  @apply text-base-100;
}

#map {
  @apply m-0 p-0 w-full h-full min-h-72 min-w-7 z-0;
}

.leaflet-tile-container {
  /* used with https://tailwind-color-filter-generator.imbios.dev/ */
  filter: invert(90%) sepia(50%) saturate(50%) hue-rotate(154deg) brightness(80%) contrast(110%);
}

/* Story */
.story {
  @apply z-0;
}

.see-more {
  @apply flex flex-col text-neutral p-8 w-full h-full justify-around items-center text-center;
  @apply backdrop-brightness-50 backdrop-contrast-75 backdrop-blur-lg;
}

/* Footer */
#footer {
  @apply p-8 text-center text-base-100 text-sm mb-4;
}

#footer p {
  @apply text-greenaccent;
}

#footer a {
  @apply underline text-base-300 font-semibold bg-greenaccent;
}

#footer a:hover {
  @apply text-accent decoration-wavy;
}
